import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/client/lib/utils"

const typographyVariants = cva(" text-sm font-medium ", {
	variants: {
		variant: {
			default: "text-neutral-400 font-medium text-sm leading-6",
			heading: "text-neutral-500 font-bold text-2xl tracking-[-0.6px]",
			text: "text-sm text-neutral-300 font-normal leading-5",
		},
		size: {
			default: "",
			xs: "text-[10px]",
			sm: "text-base",
			lg: "text-2xl",
		},
	},
	defaultVariants: {
		variant: "default",
		size: "default",
	},
})

export interface TypographyPropType
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof typographyVariants> {
	asChild?: boolean
}

const Typography = React.forwardRef<HTMLDivElement, TypographyPropType>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "div"
		return (
			<Comp
				className={cn(typographyVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		)
	}
)
Typography.displayName = "Typography"

export { Typography, typographyVariants }
