import {
	IOnboardingTitleParams,
	OnboardingPagePropType,
} from "@/client/lib/types/onboarding-types"

// TODO: rename data to a meaningful variable
// PropType for a constant in unnecessary. can be removed
export const data: OnboardingPagePropType = {
	title: "Which type of investor are you?",
	sub: "You can change it later from your account profile.",
	radioFields: [
		{
			value: "Individual Investor",
			radioOption: "Individual Investor",
			exchanges: ["NSE", "BSE"],
		},
		{
			value: "Equity Analyst",
			radioOption: "Equity Analyst",
			exchanges: ["NSE", "BSE"],
		},
		{
			value: "Financial Advisor",
			radioOption: "Financial Advisor",
			exchanges: ["NSE", "BSE"],
		},
		{
			value: "Other",
			radioOption: "Other",
			exchanges: ["NSE", "BSE"],
		},
	],
}

export const DEFAULT_INVESTOR_TYPES = data.radioFields.slice(0, data.radioFields.length - 1).map(item => item.radioOption)
export const INVESTOR_TYPE_OTHER = data.radioFields[data.radioFields.length - 1].radioOption

export const userData: IOnboardingTitleParams = {
	title: "What should we call you?",
	sub: "Name",
}
