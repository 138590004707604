import React from "react"

interface IndiaIconProps {
	className?: string
	style?: React.CSSProperties
	// add any other expected props here
}

const IndiaIcon: React.FC<IndiaIconProps> = () => (
	<svg
		width="12"
		height="9"
		viewBox="0 0 12 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g id="twemoji:flag-india">
			<path
				id="Vector"
				d="M0 7.49992C0 7.85354 0.140476 8.19268 0.390524 8.44273C0.640573 8.69278 0.979711 8.83325 1.33333 8.83325H10.6667C11.0203 8.83325 11.3594 8.69278 11.6095 8.44273C11.8595 8.19268 12 7.85354 12 7.49992V5.83325H0V7.49992Z"
				fill="#138808"
			/>
			<path
				id="Vector_2"
				d="M12 3.16663V1.49996C12 1.14634 11.8595 0.807199 11.6095 0.55715C11.3594 0.307102 11.0203 0.166626 10.6667 0.166626H1.33333C0.979711 0.166626 0.640573 0.307102 0.390524 0.55715C0.140476 0.807199 0 1.14634 0 1.49996L0 3.16663H12Z"
				fill="#FF9933"
			/>
			<path id="Vector_3" d="M0 3.05566H12V5.94466H0V3.05566Z" fill="#F7F7F7" />
			<path
				id="Vector_4"
				d="M6.00008 5.83329C6.73646 5.83329 7.33341 5.23634 7.33341 4.49996C7.33341 3.76358 6.73646 3.16663 6.00008 3.16663C5.2637 3.16663 4.66675 3.76358 4.66675 4.49996C4.66675 5.23634 5.2637 5.83329 6.00008 5.83329Z"
				fill="#000080"
			/>
			<path
				id="Vector_5"
				d="M6 5.625C6.62132 5.625 7.125 5.12132 7.125 4.5C7.125 3.87868 6.62132 3.375 6 3.375C5.37868 3.375 4.875 3.87868 4.875 4.5C4.875 5.12132 5.37868 5.625 6 5.625Z"
				fill="#F7F7F7"
			/>
			<path
				id="Vector_6"
				d="M6.03335 4.08335L6.00002 4.30002L5.96668 4.08335L6.00002 3.43335L6.03335 4.08335ZM5.72402 3.46968L5.86002 4.10602L5.94835 4.30668L5.92435 4.08902L5.72402 3.46968ZM5.46668 3.57635L5.76268 4.15568L5.90002 4.32668L5.82068 4.12235L5.46668 3.57635ZM5.24568 3.74568L5.68168 4.22902L5.85868 4.35868L5.72902 4.18168L5.24568 3.74568ZM5.07635 3.96668L5.62235 4.32068L5.82668 4.40002L5.65568 4.26268L5.07635 3.96668ZM4.96968 4.22402L5.58902 4.42435L5.80668 4.44835L5.60602 4.36002L4.96968 4.22402ZM4.93335 4.50002L5.58335 4.53335L5.80002 4.50002L5.58335 4.46668L4.93335 4.50002ZM4.96968 4.77602L5.60602 4.64002L5.80668 4.55168L5.58902 4.57568L4.96968 4.77602ZM5.07635 5.03335L5.65568 4.73735L5.82668 4.60002L5.62235 4.67935L5.07635 5.03335ZM5.24568 5.25435L5.72902 4.81835L5.85868 4.64135L5.68168 4.77102L5.24568 5.25435ZM5.46668 5.42368L5.82068 4.87768L5.90002 4.67335L5.76268 4.84435L5.46668 5.42368ZM5.72402 5.53035L5.92435 4.91102L5.94835 4.69335L5.86002 4.89402L5.72402 5.53035ZM6.00002 5.56668L6.03335 4.91668L6.00002 4.70002L5.96668 4.91668L6.00002 5.56668ZM6.27602 5.53035L6.14002 4.89402L6.05168 4.69335L6.07568 4.91102L6.27602 5.53035ZM6.53335 5.42368L6.23735 4.84435L6.10002 4.67335L6.17935 4.87768L6.53335 5.42368ZM6.75435 5.25435L6.31835 4.77102L6.14135 4.64135L6.27102 4.81835L6.75435 5.25435ZM6.92368 5.03335L6.37768 4.67935L6.17335 4.60002L6.34435 4.73735L6.92368 5.03335ZM7.03035 4.77602L6.41102 4.57568L6.19335 4.55168L6.39402 4.64002L7.03035 4.77602ZM7.06668 4.50002L6.41668 4.46668L6.20002 4.50002L6.41668 4.53335L7.06668 4.50002ZM7.03035 4.22402L6.39402 4.36002L6.19335 4.44835L6.41102 4.42435L7.03035 4.22402ZM6.92368 3.96668L6.34435 4.26268L6.17335 4.40002L6.37768 4.32068L6.92368 3.96668ZM6.75435 3.74568L6.27102 4.18168L6.14135 4.35868L6.31835 4.22902L6.75435 3.74568ZM6.53335 3.57635L6.17935 4.12235L6.10002 4.32668L6.23735 4.15568L6.53335 3.57635ZM6.27602 3.46968L6.07568 4.08902L6.05168 4.30668L6.14002 4.10602L6.27602 3.46968Z"
				fill="#6666B3"
			/>
			<g id="Group">
				<path
					id="Vector_7"
					d="M5.85329 3.45279C5.89011 3.45279 5.91995 3.42294 5.91995 3.38612C5.91995 3.34931 5.89011 3.31946 5.85329 3.31946C5.81647 3.31946 5.78662 3.34931 5.78662 3.38612C5.78662 3.42294 5.81647 3.45279 5.85329 3.45279Z"
					fill="#000080"
				/>
				<path
					id="Vector_8"
					d="M5.56984 3.52884C5.60666 3.52884 5.63651 3.49899 5.63651 3.46217C5.63651 3.42536 5.60666 3.39551 5.56984 3.39551C5.53302 3.39551 5.50317 3.42536 5.50317 3.46217C5.50317 3.49899 5.53302 3.52884 5.56984 3.52884Z"
					fill="#000080"
				/>
				<path
					id="Vector_9"
					d="M5.31593 3.6752C5.35275 3.6752 5.3826 3.64536 5.3826 3.60854C5.3826 3.57172 5.35275 3.54187 5.31593 3.54187C5.27912 3.54187 5.24927 3.57172 5.24927 3.60854C5.24927 3.64536 5.27912 3.6752 5.31593 3.6752Z"
					fill="#000080"
				/>
				<path
					id="Vector_10"
					d="M5.10866 3.88248C5.14548 3.88248 5.17533 3.85263 5.17533 3.81581C5.17533 3.77899 5.14548 3.74915 5.10866 3.74915C5.07184 3.74915 5.04199 3.77899 5.04199 3.81581C5.04199 3.85263 5.07184 3.88248 5.10866 3.88248Z"
					fill="#000080"
				/>
				<path
					id="Vector_11"
					d="M4.96217 4.13651C4.99899 4.13651 5.02884 4.10666 5.02884 4.06984C5.02884 4.03302 4.99899 4.00317 4.96217 4.00317C4.92536 4.00317 4.89551 4.03302 4.89551 4.06984C4.89551 4.10666 4.92536 4.13651 4.96217 4.13651Z"
					fill="#000080"
				/>
				<path
					id="Vector_12"
					d="M4.88625 4.41983C4.92307 4.41983 4.95291 4.38998 4.95291 4.35317C4.95291 4.31635 4.92307 4.2865 4.88625 4.2865C4.84943 4.2865 4.81958 4.31635 4.81958 4.35317C4.81958 4.38998 4.84943 4.41983 4.88625 4.41983Z"
					fill="#000080"
				/>
				<path
					id="Vector_13"
					d="M4.88625 4.71317C4.92307 4.71317 4.95291 4.68332 4.95291 4.6465C4.95291 4.60968 4.92307 4.57983 4.88625 4.57983C4.84943 4.57983 4.81958 4.60968 4.81958 4.6465C4.81958 4.68332 4.84943 4.71317 4.88625 4.71317Z"
					fill="#000080"
				/>
				<path
					id="Vector_14"
					d="M4.96217 4.99649C4.99899 4.99649 5.02884 4.96665 5.02884 4.92983C5.02884 4.89301 4.99899 4.86316 4.96217 4.86316C4.92536 4.86316 4.89551 4.89301 4.89551 4.92983C4.89551 4.96665 4.92536 4.99649 4.96217 4.99649Z"
					fill="#000080"
				/>
				<path
					id="Vector_15"
					d="M5.10866 5.25052C5.14548 5.25052 5.17533 5.22067 5.17533 5.18385C5.17533 5.14704 5.14548 5.11719 5.10866 5.11719C5.07184 5.11719 5.04199 5.14704 5.04199 5.18385C5.04199 5.22067 5.07184 5.25052 5.10866 5.25052Z"
					fill="#000080"
				/>
				<path
					id="Vector_16"
					d="M5.31593 5.4578C5.35275 5.4578 5.3826 5.42795 5.3826 5.39113C5.3826 5.35431 5.35275 5.32446 5.31593 5.32446C5.27912 5.32446 5.24927 5.35431 5.24927 5.39113C5.24927 5.42795 5.27912 5.4578 5.31593 5.4578Z"
					fill="#000080"
				/>
				<path
					id="Vector_17"
					d="M5.56984 5.60416C5.60666 5.60416 5.63651 5.57431 5.63651 5.53749C5.63651 5.50067 5.60666 5.47083 5.56984 5.47083C5.53302 5.47083 5.50317 5.50067 5.50317 5.53749C5.50317 5.57431 5.53302 5.60416 5.56984 5.60416Z"
					fill="#000080"
				/>
				<path
					id="Vector_18"
					d="M5.85329 5.68009C5.89011 5.68009 5.91995 5.65024 5.91995 5.61342C5.91995 5.5766 5.89011 5.54675 5.85329 5.54675C5.81647 5.54675 5.78662 5.5766 5.78662 5.61342C5.78662 5.65024 5.81647 5.68009 5.85329 5.68009Z"
					fill="#000080"
				/>
				<path
					id="Vector_19"
					d="M6.1465 5.68009C6.18332 5.68009 6.21317 5.65024 6.21317 5.61342C6.21317 5.5766 6.18332 5.54675 6.1465 5.54675C6.10968 5.54675 6.07983 5.5766 6.07983 5.61342C6.07983 5.65024 6.10968 5.68009 6.1465 5.68009Z"
					fill="#000080"
				/>
				<path
					id="Vector_20"
					d="M6.42995 5.60416C6.46677 5.60416 6.49661 5.57431 6.49661 5.53749C6.49661 5.50067 6.46677 5.47083 6.42995 5.47083C6.39313 5.47083 6.36328 5.50067 6.36328 5.53749C6.36328 5.57431 6.39313 5.60416 6.42995 5.60416Z"
					fill="#000080"
				/>
				<path
					id="Vector_21"
					d="M6.68385 5.4578C6.72067 5.4578 6.75052 5.42795 6.75052 5.39113C6.75052 5.35431 6.72067 5.32446 6.68385 5.32446C6.64704 5.32446 6.61719 5.35431 6.61719 5.39113C6.61719 5.42795 6.64704 5.4578 6.68385 5.4578Z"
					fill="#000080"
				/>
				<path
					id="Vector_22"
					d="M6.89113 5.25052C6.92795 5.25052 6.9578 5.22067 6.9578 5.18385C6.9578 5.14704 6.92795 5.11719 6.89113 5.11719C6.85431 5.11719 6.82446 5.14704 6.82446 5.18385C6.82446 5.22067 6.85431 5.25052 6.89113 5.25052Z"
					fill="#000080"
				/>
				<path
					id="Vector_23"
					d="M7.03761 4.99649C7.07443 4.99649 7.10428 4.96665 7.10428 4.92983C7.10428 4.89301 7.07443 4.86316 7.03761 4.86316C7.0008 4.86316 6.97095 4.89301 6.97095 4.92983C6.97095 4.96665 7.0008 4.99649 7.03761 4.99649Z"
					fill="#000080"
				/>
				<path
					id="Vector_24"
					d="M7.11354 4.71317C7.15036 4.71317 7.18021 4.68332 7.18021 4.6465C7.18021 4.60968 7.15036 4.57983 7.11354 4.57983C7.07672 4.57983 7.04688 4.60968 7.04688 4.6465C7.04688 4.68332 7.07672 4.71317 7.11354 4.71317Z"
					fill="#000080"
				/>
				<path
					id="Vector_25"
					d="M7.11354 4.41983C7.15036 4.41983 7.18021 4.38998 7.18021 4.35317C7.18021 4.31635 7.15036 4.2865 7.11354 4.2865C7.07672 4.2865 7.04688 4.31635 7.04688 4.35317C7.04688 4.38998 7.07672 4.41983 7.11354 4.41983Z"
					fill="#000080"
				/>
				<path
					id="Vector_26"
					d="M7.03761 4.13651C7.07443 4.13651 7.10428 4.10666 7.10428 4.06984C7.10428 4.03302 7.07443 4.00317 7.03761 4.00317C7.0008 4.00317 6.97095 4.03302 6.97095 4.06984C6.97095 4.10666 7.0008 4.13651 7.03761 4.13651Z"
					fill="#000080"
				/>
				<path
					id="Vector_27"
					d="M6.89113 3.88248C6.92795 3.88248 6.9578 3.85263 6.9578 3.81581C6.9578 3.77899 6.92795 3.74915 6.89113 3.74915C6.85431 3.74915 6.82446 3.77899 6.82446 3.81581C6.82446 3.85263 6.85431 3.88248 6.89113 3.88248Z"
					fill="#000080"
				/>
				<path
					id="Vector_28"
					d="M6.68385 3.6752C6.72067 3.6752 6.75052 3.64536 6.75052 3.60854C6.75052 3.57172 6.72067 3.54187 6.68385 3.54187C6.64704 3.54187 6.61719 3.57172 6.61719 3.60854C6.61719 3.64536 6.64704 3.6752 6.68385 3.6752Z"
					fill="#000080"
				/>
				<path
					id="Vector_29"
					d="M6.42995 3.52884C6.46677 3.52884 6.49661 3.49899 6.49661 3.46217C6.49661 3.42536 6.46677 3.39551 6.42995 3.39551C6.39313 3.39551 6.36328 3.42536 6.36328 3.46217C6.36328 3.49899 6.39313 3.52884 6.42995 3.52884Z"
					fill="#000080"
				/>
				<path
					id="Vector_30"
					d="M6.1465 3.45279C6.18332 3.45279 6.21317 3.42294 6.21317 3.38612C6.21317 3.34931 6.18332 3.31946 6.1465 3.31946C6.10968 3.31946 6.07983 3.34931 6.07983 3.38612C6.07983 3.42294 6.10968 3.45279 6.1465 3.45279Z"
					fill="#000080"
				/>
				<path
					id="Vector_31"
					d="M5.99995 4.79983C6.16564 4.79983 6.29995 4.66551 6.29995 4.49983C6.29995 4.33414 6.16564 4.19983 5.99995 4.19983C5.83427 4.19983 5.69995 4.33414 5.69995 4.49983C5.69995 4.66551 5.83427 4.79983 5.99995 4.79983Z"
					fill="#000080"
				/>
			</g>
		</g>
	</svg>
)

export default IndiaIcon
