"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import IndiaIcon from "@/client/lib/svgs/india"
import UsaIcon from "@/client/lib/svgs/usa"
import { useInfos, useLocals } from "@/client/store/store"
import OnboardingTitle from "@/client/components/atoms/onboarding-title"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/client/components/atoms/ui/form"
import { RadioGroup } from "@/client/components/atoms/ui/radio-group"
import NextStepNavigation from "@/client/components/atoms/next-step"
import RadioButton from "@/client/components/atoms/radio-button"
import {
	OnboardingPagePropType,
	OnboardingCountrySelectorType,
} from "@/client/lib/types/onboarding-types"
import { Typography } from "@/client/components/atoms/heading"
import { AccountSchema } from "@/client/store/account"

const FormSchema = z.object({
	type: AccountSchema.PreferredCountrySchema
})

export const data: OnboardingPagePropType = {
	title: "Which market are you interested in?",
	sub: "We’ll set the default screens to your preferred market.",
	radioFields: [
		{
			country: "USA",
			radioOption: "us",
			value: "US",
			logo: <UsaIcon />,
			exchanges: ["NYSE", "Nasdaq"],
		},
		{
			country: "INDIA",
			radioOption: "in",
			value: "IN",
			logo: <IndiaIcon />,
			exchanges: ["NSE", "BSE"],
		},
	],
}

export function OnboardingMarketType() {
	const setInfos = useInfos(state => state.setInfos)
	const setCurrentStep = useLocals(state => state.setCurrentStep)

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		mode: "onChange",
	})

	const onSubmit = (data: z.infer<typeof FormSchema>) => {
		setInfos("preferred_country", data.type)
		setCurrentStep(1)
	}

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="w-full flex flex-col gap-10">
				<FormField
					control={form.control}
					name="type"
					render={({ field }) => (
						<FormItem className="flex flex-col gap-5">
							<FormLabel className="flex flex-col gap-y-2.5 justify-start">
								<OnboardingTitle title={data.title} sub={data.sub} />
							</FormLabel>
							<FormControl>
								<RadioGroup
									onValueChange={field.onChange}
									defaultValue={field.value}
									className="flex flex-col space-y-1 focus:border-neutral-300">
									{data.radioFields &&
										data.radioFields.map(
											(item: OnboardingCountrySelectorType, index) => (
												<RadioButton
													key={`market-type-${index}`}
													checked={field.value === item.value}
													value={item.value}
													onSelect={() => { }}>
													<>
														<div className="flex items-center gap-1">
															<Typography size="sm">{item.country}</Typography>
															{item.logo}
														</div>
														<Typography
															variant="text"
															size="xs"
															className="flex gap-1">
															{item.exchanges &&
																item.exchanges.map((exchange, index) => {
																	return (
																		<div key={`exchange_${index}`}>
																			<span className="flex">
																				{exchange.toUpperCase()}
																				{index < item.exchanges.length - 1
																					? ","
																					: " "}
																			</span>
																		</div>
																	)
																})}
														</Typography>
													</>
												</RadioButton>
											)
										)}
								</RadioGroup>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<div className="w-full flex justify-end">
					<NextStepNavigation />
				</div>
			</form>
		</Form>
	)
}
