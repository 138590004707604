import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { Slot } from "@radix-ui/react-slot"
import {
	Controller,
	ControllerProps,
	FieldPath,
	FieldValues,
	FormProvider,
	useFormContext,
} from "react-hook-form"

import { cn } from "@/client/lib/utils"
import { Label } from "@/client/components/atoms/ui/label"

const Form = FormProvider

type FormFieldContextValue<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
	name: TName
}

const FormFieldContext = React.createContext<FormFieldContextValue>(
	{} as FormFieldContextValue
)

const FormField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	...props
}: ControllerProps<TFieldValues, TName>) => {
	return (
		<FormFieldContext.Provider value={{ name: props.name }}>
			<Controller {...props} />
		</FormFieldContext.Provider>
	)
}

const useFormField = () => {
	const fieldContext = React.useContext(FormFieldContext)
	const itemContext = React.useContext(FormItemContext)
	const { getFieldState, formState } = useFormContext()

	const fieldState = getFieldState(fieldContext.name, formState)

	if (!fieldContext) {
		throw new Error("useFormField should be used within <FormField>")
	}

	const { id } = itemContext

	return {
		id,
		name: fieldContext.name,
		formItemId: `${id}-form-item`,
		formDescriptionId: `${id}-form-item-description`,
		formMessageId: `${id}-form-item-message`,
		...fieldState,
	}
}

type FormItemContextValue = {
	id: string
}

const FormItemContext = React.createContext<FormItemContextValue>(
	{} as FormItemContextValue
)

const FormItem = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
	// eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => {
	const id = React.useId()

	return (
		<FormItemContext.Provider value={{ id }}>
			<div ref={ref} className={cn("", className)} {...props} />
		</FormItemContext.Provider>
	)
})
FormItem.displayName = "FormItem"

const FormLabel = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
	// eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => {
	const { formItemId } = useFormField()

	return (
		<Label
			ref={ref}
			className={cn(className)}
			htmlFor={formItemId}
			{...props}
		/>
	)
})
FormLabel.displayName = "FormLabel"

const FormControl = React.forwardRef<
	React.ElementRef<typeof Slot>,
	React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
	const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

	return (
		<Slot
			ref={ref}
			id={formItemId}
			aria-describedby={
				!error
					? `${formDescriptionId}`
					: `${formDescriptionId} ${formMessageId}`
			}
			aria-invalid={!!error}
			{...props}
		/>
	)
})
FormControl.displayName = "FormControl"

const FormDescription = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
	// eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => {
	const { formDescriptionId } = useFormField()

	return (
		<p
			ref={ref}
			id={formDescriptionId}
			className={cn("text-sm text-muted-foreground", className)}
			{...props}
		/>
	)
})
FormDescription.displayName = "FormDescription"

const FormMessage = React.forwardRef<
	HTMLParagraphElement,
	React.HTMLAttributes<HTMLParagraphElement>
	// eslint-disable-next-line react/prop-types
>(({ className, children, ...props }, ref) => {
	const { error, formMessageId } = useFormField()
	const body = error ? String(error?.message) : children

	if (!body) {
		return null
	}

	return (
		<p
			ref={ref}
			id={formMessageId}
			className={cn(
				"text-sm font-medium text-destructive flex gap-2 items-center",
				className
			)}
			{...props}>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<g id="Mask group">
					<mask
						id="mask0_188_1444"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="16"
						height="16">
						<g id="Group">
							<g id="Group_2">
								<path
									id="Vector"
									d="M8 15C8.91943 15.0012 9.83002 14.8207 10.6795 14.4688C11.5289 14.1169 12.3004 13.6007 12.9497 12.9497C13.6007 12.3004 14.1169 11.5289 14.4688 10.6795C14.8207 9.83002 15.0012 8.91943 15 8C15.0012 7.08058 14.8206 6.16998 14.4688 5.32055C14.1169 4.47112 13.6007 3.69959 12.9497 3.0503C12.3004 2.39931 11.5289 1.88305 10.6795 1.5312C9.83002 1.17934 8.91943 0.998815 8 1.00001C7.08058 0.998834 6.16998 1.17936 5.32055 1.53122C4.47112 1.88308 3.69959 2.39933 3.0503 3.0503C2.39933 3.69959 1.88308 4.47112 1.53122 5.32055C1.17936 6.16998 0.998834 7.08058 1.00001 8C0.998815 8.91943 1.17934 9.83002 1.5312 10.6795C1.88305 11.5289 2.39931 12.3004 3.0503 12.9497C3.69959 13.6007 4.47112 14.1169 5.32055 14.4688C6.16998 14.8206 7.08058 15.0012 8 15Z"
									fill="white"
									stroke="white"
									strokeWidth="1.4"
									strokeLinejoin="round"
								/>
								<path
									id="Vector_2"
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8 12.5497C8.23206 12.5497 8.45462 12.4576 8.61872 12.2935C8.78281 12.1294 8.875 11.9068 8.875 11.6747C8.875 11.4427 8.78281 11.2201 8.61872 11.056C8.45462 10.8919 8.23206 10.7997 8 10.7997C7.76794 10.7997 7.54538 10.8919 7.38128 11.056C7.21719 11.2201 7.125 11.4427 7.125 11.6747C7.125 11.9068 7.21719 12.1294 7.38128 12.2935C7.54538 12.4576 7.76794 12.5497 8 12.5497Z"
									fill="black"
								/>
								<path
									id="Vector_3"
									d="M8 3.79974V9.39974"
									stroke="black"
									strokeWidth="1.4"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</g>
					</mask>
					<g mask="url(#mask0_188_1444)">
						<path
							id="Vector_4"
							d="M-0.400146 -0.399872H16.3998V16.4001H-0.400146V-0.399872Z"
							fill="#FF5454"
						/>
					</g>
				</g>
			</svg>

			{body}
		</p>
	)
})
FormMessage.displayName = "FormMessage"

export {
	useFormField,
	Form,
	FormItem,
	FormLabel,
	FormControl,
	FormDescription,
	FormMessage,
	FormField,
}
