import React from "react"
import { Typography } from "@/client/components/atoms/heading"

import { IOnboardingTitleParams } from "@/client/lib/types"

export default function OnboardingTitle({
	title,
	sub,
}: IOnboardingTitleParams) {
	return (
		<>
			<Typography variant="heading">{title}</Typography>
			<Typography variant="text">{sub}</Typography>
		</>
	)
}
