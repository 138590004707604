import React from "react"
import {
	FormControl,
	FormItem,
	FormLabel,
} from "@/client/components/atoms/ui/form"
import { RadioGroupItem } from "@/client/components/atoms/ui/radio-group"

type RadioButtonProps = {
	checked: boolean
	children: React.ReactNode
	value: string
	onSelect: () => void
}

const RadioButton = ({
	checked,
	onSelect,
	value,
	children,
}: RadioButtonProps) => (
	<FormItem
		className={`border hover:border-neutral-300 bg-white flex items-center gap-2.5 pl-3.5 rounded-md border-solid max-md:max-w-full h-[60px] cursor-pointer ${
			checked ? "border-neutral-300" : ""
		}`}>
		<FormControl>
			<RadioGroupItem checked={checked} value={value} />
		</FormControl>
		<FormLabel
			className="font-normal flex w-full h-full flex-col items-stretch cursor-pointer"
			onClick={onSelect}>
			<div className="items-stretch self-stretch flex grow basis-[0%] flex-col justify-center">
				{children}
			</div>
		</FormLabel>
	</FormItem>
)

export default RadioButton
